import React from 'react'
import Layout from 'src/components/Layout'
import peg, { responsive } from 'src/pegTheme'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { Title } from 'src/components/typography'
import paul from 'src/components/demo/paul.jpg'
import RoundThumbnail from 'src/components/misc/RoundThumbnail'
import HubspotForm from 'react-hubspot-form'

export default () => (
  <Layout>
    <Helmet>
      <title>Try YouTube & Instagram Influencer Marketing Tool Free | Peg</title>
      <meta name='description' content='Our influencer marketing tool & platform allows companies and fantastic content creators to collaborate and produce beautiful content. Get your free demo today!' />
    </Helmet>

    <DemoSection>
      <DemoLeft>
        <DemoTitle left>Let's get started with your 48 hour trial!</DemoTitle> :

        <QuoteContainer>
          <RoundThumbnail src={ paul } width={ 100 } />
          <InlineQuote>
            Brand safety had become a paramount factor for us when working with influencers. We thoroughly researched the market and decided that Peg were by far the best technology company for us to partner with.
          </InlineQuote>
        </QuoteContainer>
        <p>— Paul Frampton, CEO at Havas Media (UK)</p>
      </DemoLeft>

      <DemoRight>
        <HubspotForm
          portalId='4746694'
          formId='13da807e-54f3-4083-96d2-a89db4819f33'
        />
      </DemoRight>
    </DemoSection>
  </Layout>
)

const DemoTitle = styled(Title)`
  margin-top: 60px;
  margin-bottom: ${ peg.spacing('m') };
`

const DemoSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 100px;
`

const DemoLeft = styled.div`
  width: 50%;
  ${ responsive('display', { d: 'block', m: 'none' }) }
  padding-right: ${ peg.spacing('xl') };
`

const DemoRight = styled.div`
  ${ responsive('width', { d: '50%', m: '100%' }) }
  padding: ${ peg.spacing('l') } ${ peg.spacing('xl') } 0;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const InlineQuote = styled.p`
  quotes: "“" "”" "‘" "’";
  font-size: ${ peg.fs('m ') };
  margin-left: ${ peg.spacing('l') };
  margin-bottom: 0;

  &:before {
    content: open-quote;
    left: -10px;
    top: 10px;
    margin-left: 10px;
  }

  &::after {
    content: close-quote;
    left: 10px;
    top: 20px;
  }

  &::before, &::after {
    display: inline;
    font-family: Times, sans-serif;
    height: 0;
    line-height: 0;
    position: relative;
    font-size: 40px;
    color: ${ peg.color('blue') };
  }
`

const QuoteContainer = styled.div`
  display: flex;
  align-items: center;
`