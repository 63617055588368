import styled from '@emotion/styled'

export default styled.img`
  background: #fff;
  z-index: 3;
  width: ${ props => `${ props.width || 150 }px` };
  height: ${ props => `${ props.width || 150 }px` };
  border-radius: ${ props => `${ (props.width || 150) / 2 }px` };
  display: block;
  position: relative;
  border: 4px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px;
  flex-shrink: 0;
`